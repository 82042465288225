import { EyeIcon } from '@heroicons/react/20/solid'
import { Link } from 'react-router-dom'
import he from 'he'
import {
  Key,
  ReactElement,
  JSXElementConstructor,
  ReactNode,
  ReactPortal,
} from 'react'

const ProjectCard = ({ userProjects }) => {
  return userProjects.map(
    (project: {
      _id: Key
      title:
        | string
        | number
        | boolean
        | ReactElement<any, string | JSXElementConstructor<any>>
        | Iterable<ReactNode>
        | ReactPortal
      status:
        | string
        | number
        | boolean
        | ReactElement<any, string | JSXElementConstructor<any>>
        | Iterable<ReactNode>
      description: string
      budget: string
    }) => (
      <li
        key={project._id}
        className="col-span-3 list-none divide-y divide-gray-200 rounded-lg bg-white dark:bg-gray-950 shadow"
      >
        <div className="flex w-full items-center justify-between space-x-6 p-6">
          <div className="flex-1 truncate">
            <div className="flex items-center space-x-3">
              <h3 className="truncate text-sm font-medium dark:text-white text-gray-900">
                {project.title}
              </h3>
              <span
                className={
                  project.status === 'Approved'
                    ? 'inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-800'
                    : project.status === 'Pending'
                    ? 'inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-yellow-100 text-yellow-800'
                    : project.status === 'Rejected'
                    ? 'inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-red-100 text-red-800'
                    : 'inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-gray-100 text-gray-800'
                }
              >
                {project.status}
              </span>
            </div>
            <p
              className="mt-1 truncate text-sm dark:text-white text-gray-500"
              dangerouslySetInnerHTML={{
                __html: he.decode(project.description).substring(0, 200),
              }}
            />
          </div>
          <div>
            <h3 className="dark:text-white text-gray-700">
              {`$` + project.budget}
            </h3>
          </div>
        </div>
        <div>
          <div className="-mt-px flex divide-x divide-gray-200 dark:border dark:border-white">
            <div className="flex w-0 flex-1">
              <Link
                to={`/admin/projects/` + `${project._id}`}
                className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold dark:text-white text-gray-900"
              >
                <EyeIcon
                  className="h-5 w-5 text-gray-400 dark:text-white"
                  aria-hidden="true"
                />
                View Proposals
              </Link>
            </div>
          </div>
        </div>
      </li>
    )
  )
}
export default ProjectCard

import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { useForm, useFieldArray } from 'react-hook-form'
import BreadCrumb from '../../../../components/BreadCrumb/Index'
import AdminLayout from '../../../../components/Layout/AdminLayout/Index'
import Spinner from '../../../../components/Spinner/Index'
import Swal from 'sweetalert2'
import {
  fetchPlatformById,
  updatePlatform,
} from '../../../../store/platforms/platformReducer'

const EditPlatform = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const platformId = useParams().id
  const platform = useSelector((state: any) => state.platforms.platform)

  const { register, handleSubmit, control, reset } = useForm({
    defaultValues: {
      name: '',
      rating: '',
      description: '',
      website: '',
      image_url: '',
      affiliate_url: '',
      demo_video_url: '',
      twitter_url: '',
      facebook_url: '',
      linkedin_url: '',
      pricing: [
        {
          name: '',
          price: '',
          billing_frequency: '',
          trial: '',
          features: [''],
        },
      ],
      frequently_asked_questions: [{ question: '', answer: '' }],
      features: [''],
    },
  })

  const {
    fields: pricingFields,
    append: appendPricing,
    remove: removePricing,
  } = useFieldArray({
    control,
    name: 'pricing',
  })

  const {
    fields: faqFields,
    append: appendFAQ,
    remove: removeFAQ,
  } = useFieldArray({
    control,
    name: 'frequently_asked_questions',
  })

  const {
    fields: featuresFields,
    append: appendFeature,
    remove: removeFeature,
  } = useFieldArray({
    control,
    name: 'features',
  })

  useEffect(() => {
    dispatch(fetchPlatformById(platformId)).then((response: any) => {
      if (response.payload) {
        reset({
          name: response.payload.name,
          rating: response.payload.rating,
          description: response.payload.description,
          website: response.payload.website,
          image_url: response.payload.image_url,
          affiliate_url: response.payload.affiliate_url,
          demo_video_url: response.payload.demo_video_url,
          twitter_url: response.payload.twitter_url,
          facebook_url: response.payload.facebook_url,
          linkedin_url: response.payload.linkedin_url,
          pricing: response.payload.pricing || [],
          frequently_asked_questions:
            response.payload.frequently_asked_questions?.questions || [],
          features: response.payload.features || [],
        })
      }
    })
  }, [dispatch, platformId, reset])

  const onSubmit = (data: any) => {
    dispatch(updatePlatform({ id: platformId, formData: data }))
      .then(() => {
        Swal.fire({
          title: 'Platform Updated!',
          text: 'The platform details have been successfully updated.',
          icon: 'success',
          confirmButtonText: 'OK',
        }).then(() => navigate(`/admin/platforms/${platformId}`))
      })
      .catch(() => {
        Swal.fire({
          title: 'Error!',
          text: 'There was an error updating the platform.',
          icon: 'error',
          confirmButtonText: 'OK',
        })
      })
  }

  if (!platform) {
    return <Spinner />
  }

  return (
    <AdminLayout>
      <BreadCrumb title="Edit Platform" url={`/admin/platforms`} />
      <div className="m-4 bg-white dark:bg-gray-950 rounded-lg shadow">
        <div className="p-6">
          <h1 className="text-xl font-semibold mb-4">Edit Platform</h1>
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* Name */}
            <div className="mb-4">
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700 dark:text-white"
              >
                Platform Name
              </label>
              <input
                type="text"
                {...register('name')}
                className="mt-1 block w-full px-3 py-2 bg-white dark:bg-gray-900 border border-gray-300 dark:border-gray-700 rounded-md shadow-sm focus:outline-none"
              />
            </div>

            {/* Rating */}
            <div className="mb-4">
              <label
                htmlFor="rating"
                className="block text-sm font-medium text-gray-700 dark:text-white"
              >
                Rating
              </label>
              <input
                type="number"
                step="0.01"
                {...register('rating')}
                className="mt-1 block w-full px-3 py-2 bg-white dark:bg-gray-900 border border-gray-300 dark:border-gray-700 rounded-md shadow-sm focus:outline-none"
              />
            </div>

            {/* Description */}
            <div className="mb-4">
              <label
                htmlFor="description"
                className="block text-sm font-medium text-gray-700 dark:text-white"
              >
                Description
              </label>
              <textarea
                {...register('description')}
                className="mt-1 block w-full px-3 py-2 bg-white dark:bg-gray-900 border border-gray-300 dark:border-gray-700 rounded-md shadow-sm focus:outline-none"
              />
            </div>

            {/* Website */}
            <div className="mb-4">
              <label
                htmlFor="website"
                className="block text-sm font-medium text-gray-700 dark:text-white"
              >
                Website
              </label>
              <input
                type="url"
                {...register('website')}
                className="mt-1 block w-full px-3 py-2 bg-white dark:bg-gray-900 border border-gray-300 dark:border-gray-700 rounded-md shadow-sm focus:outline-none"
              />
            </div>

            {/* Image URL */}
            <div className="mb-4">
              <label
                htmlFor="image_url"
                className="block text-sm font-medium text-gray-700 dark:text-white"
              >
                Image URL
              </label>
              <input
                type="text"
                {...register('image_url')}
                className="mt-1 block w-full px-3 py-2 bg-white dark:bg-gray-900 border border-gray-300 dark:border-gray-700 rounded-md shadow-sm focus:outline-none"
              />
            </div>

            {/* Social Media Links */}
            <div className="mb-4">
              <label
                htmlFor="twitter_url"
                className="block text-sm font-medium text-gray-700 dark:text-white"
              >
                Twitter URL
              </label>
              <input
                type="text"
                {...register('twitter_url')}
                className="mt-1 block w-full px-3 py-2 bg-white dark:bg-gray-900 border border-gray-300 dark:border-gray-700 rounded-md shadow-sm focus:outline-none"
              />
            </div>

            <div className="mb-4">
              <label
                htmlFor="facebook_url"
                className="block text-sm font-medium text-gray-700 dark:text-white"
              >
                Facebook URL
              </label>
              <input
                type="text"
                {...register('facebook_url')}
                className="mt-1 block w-full px-3 py-2 bg-white dark:bg-gray-900 border border-gray-300 dark:border-gray-700 rounded-md shadow-sm focus:outline-none"
              />
            </div>

            <div className="mb-4">
              <label
                htmlFor="linkedin_url"
                className="block text-sm font-medium text-gray-700 dark:text-white"
              >
                LinkedIn URL
              </label>
              <input
                type="text"
                {...register('linkedin_url')}
                className="mt-1 block w-full px-3 py-2 bg-white dark:bg-gray-900 border border-gray-300 dark:border-gray-700 rounded-md shadow-sm focus:outline-none"
              />
            </div>

            {/* Pricing Plans */}
            <div className="mb-4">
              <h3 className="text-lg font-semibold">Pricing Plans</h3>
              {pricingFields.map((item, index) => (
                <div key={item.id} className="mb-4 border p-4 rounded">
                  <label
                    htmlFor={`pricing.${index}.name`}
                    className="block text-sm font-medium text-gray-700 dark:text-white"
                  >
                    Plan Name
                  </label>
                  <input
                    type="text"
                    {...register(`pricing.${index}.name`)}
                    className="mt-1 block w-full px-3 py-2 bg-white dark:bg-gray-900 border border-gray-300 dark:border-gray-700 rounded-md shadow-sm"
                  />

                  <label
                    htmlFor={`pricing.${index}.price`}
                    className="block text-sm font-medium text-gray-700 dark:text-white mt-2"
                  >
                    Price
                  </label>
                  <input
                    type="text"
                    {...register(`pricing.${index}.price`)}
                    className="mt-1 block w-full px-3 py-2 bg-white dark:bg-gray-900 border border-gray-300 dark:border-gray-700 rounded-md shadow-sm"
                  />

                  <label
                    htmlFor={`pricing.${index}.billing_frequency`}
                    className="block text-sm font-medium text-gray-700 dark:text-white mt-2"
                  >
                    Billing Frequency
                  </label>
                  <input
                    type="text"
                    {...register(`pricing.${index}.billing_frequency`)}
                    className="mt-1 block w-full px-3 py-2 bg-white dark:bg-gray-900 border border-gray-300 dark:border-gray-700 rounded-md shadow-sm"
                  />

                  <label
                    htmlFor={`pricing.${index}.trial`}
                    className="block text-sm font-medium text-gray-700 dark:text-white mt-2"
                  >
                    Trial
                  </label>
                  <input
                    type="text"
                    {...register(`pricing.${index}.trial`)}
                    className="mt-1 block w-full px-3 py-2 bg-white dark:bg-gray-900 border border-gray-300 dark:border-gray-700 rounded-md shadow-sm"
                  />

                  {/* Pricing Features */}
                  <label
                    htmlFor={`pricing.${index}.features`}
                    className="block text-sm font-medium text-gray-700 dark:text-white mt-2"
                  >
                    Features
                  </label>
                  <textarea
                    {...register(`pricing.${index}.features`)}
                    className="mt-1 block w-full px-3 py-2 bg-white dark:bg-gray-900 border border-gray-300 dark:border-gray-700 rounded-md shadow-sm"
                  />
                  <button
                    type="button"
                    onClick={() => removePricing(index)}
                    className="text-red-600 mt-2"
                  >
                    Remove Plan
                  </button>
                </div>
              ))}
              <button
                type="button"
                onClick={() =>
                  appendPricing({
                    name: '',
                    price: '',
                    billing_frequency: '',
                    trial: '',
                    features: [],
                  })
                }
                className="text-purple-600"
              >
                Add Plan
              </button>
            </div>

            {/* Frequently Asked Questions */}
            <div className="mb-4">
              <h3 className="text-lg font-semibold">FAQs</h3>
              {faqFields.map((item, index) => (
                <div key={item.id} className="mb-4 border p-4 rounded">
                  <label
                    htmlFor={`frequently_asked_questions.${index}.question`}
                    className="block text-sm font-medium text-gray-700 dark:text-white"
                  >
                    Question
                  </label>
                  <input
                    type="text"
                    {...register(
                      `frequently_asked_questions.${index}.question`
                    )}
                    className="mt-1 block w-full px-3 py-2 bg-white dark:bg-gray-900 border border-gray-300 dark:border-gray-700 rounded-md shadow-sm"
                  />

                  <label
                    htmlFor={`frequently_asked_questions.${index}.answer`}
                    className="block text-sm font-medium text-gray-700 dark:text-white mt-2"
                  >
                    Answer
                  </label>
                  <input
                    type="text"
                    {...register(`frequently_asked_questions.${index}.answer`)}
                    className="mt-1 block w-full px-3 py-2 bg-white dark:bg-gray-900 border border-gray-300 dark:border-gray-700 rounded-md shadow-sm"
                  />

                  <button
                    type="button"
                    onClick={() => removeFAQ(index)}
                    className="text-red-600 mt-2"
                  >
                    Remove FAQ
                  </button>
                </div>
              ))}
              <button
                type="button"
                onClick={() => appendFAQ({ question: '', answer: '' })}
                className="text-purple-600"
              >
                Add FAQ
              </button>
            </div>

            {/* Features */}
            <div className="mb-4">
              <h3 className="text-lg font-semibold">Features</h3>
              {featuresFields.map((item, index) => (
                <div key={item.id} className="mb-4 border p-4 rounded">
                  <label
                    htmlFor={`features.${index}`}
                    className="block text-sm font-medium text-gray-700 dark:text-white"
                  >
                    Feature
                  </label>
                  <input
                    type="text"
                    {...register(`features.${index}`)}
                    className="mt-1 block w-full px-3 py-2 bg-white dark:bg-gray-900 border border-gray-300 dark:border-gray-700 rounded-md shadow-sm"
                  />

                  <button
                    type="button"
                    onClick={() => removeFeature(index)}
                    className="text-red-600 mt-2"
                  >
                    Remove Feature
                  </button>
                </div>
              ))}
              <button
                type="button"
                onClick={() => appendFeature('')}
                className="text-purple-600"
              >
                Add Feature
              </button>
            </div>

            <button
              type="submit"
              className="px-4 py-2 bg-purple-600 text-white rounded-md shadow-md hover:bg-purple-700"
            >
              Save Changes
            </button>
          </form>
        </div>
      </div>
    </AdminLayout>
  )
}

export default EditPlatform

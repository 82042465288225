import { Fragment } from 'react'
import { Route, Routes } from 'react-router-dom'
import Login from '../../auth/Login/Index'
import Register from '../../auth/Register/Index'
import ForgotPassword from '../../auth/ForgotPassword/Index'
import Verification from '../../auth/Verification/Index'
import ResetPassword from '../../auth/ResetPassword/Index'

const GuestRoutes = () => {
  return (
    <Fragment>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/verify-email" element={<Verification />} />
        <Route path="/reset-password" element={<ResetPassword />} />
      </Routes>
    </Fragment>
  )
}
export default GuestRoutes

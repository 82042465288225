import { PlusCircleIcon } from '@heroicons/react/24/outline'
import AdminLayout from '../../../components/Layout/AdminLayout/Index'
import BreadCrumb from '../../../components/BreadCrumb/Index'
import Panel from '../../../components/Panel/Index'

const PermissionsManagement = () => {
  return (
    <AdminLayout>
      <BreadCrumb title="Permissions Management" url="/admin/" />
      <Panel
        title="Permissions"
        description="View and manage permissions."
        url="/admin/permissions/new"
        icon={PlusCircleIcon}
      />
    </AdminLayout>
  )
}
export default PermissionsManagement

import AdminLayout from '../../../components/Layout/AdminLayout/Index'
import { PlusCircleIcon } from '@heroicons/react/24/outline'
import BreadCrumb from '../../../components/BreadCrumb/Index'
import Panel from '../../../components/Panel/Index'

const RolesManagement = () => {
  return (
    <AdminLayout>
      <BreadCrumb title="Roles Management" url="/admin/" />
      <Panel
        title="Roles"
        description="View and manage roles."
        url="/admin/roles/new"
        icon={PlusCircleIcon}
      ></Panel>
    </AdminLayout>
  )
}
export default RolesManagement

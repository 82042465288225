import { PlusCircleIcon } from '@heroicons/react/24/outline'
import BreadCrumb from '../../../../components/BreadCrumb/Index'
import AdminLayout from '../../../../components/Layout/AdminLayout/Index'
import Panel from '../../../../components/Panel/Index'

const UpdateUser = () => {
  return (
    <AdminLayout>
      <BreadCrumb title="Update User" url="/admin/users" />
      <Panel
        title="Update User"
        description="Update a single user details"
        url="/admin/users"
        icon={PlusCircleIcon}
      ></Panel>
    </AdminLayout>
  )
}
export default UpdateUser

import { useForm, useFieldArray } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import AdminLayout from '../../../../components/Layout/AdminLayout/Index'
import BreadCrumb from '../../../../components/BreadCrumb/Index'
import { createPlatform } from '../../../../store/platforms/platformReducer' // Update import path if necessary

const CreateAdminPlatform = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { register, handleSubmit, control } = useForm({
    defaultValues: {
      name: '',
      rating: '',
      description: '',
      website: '',
      image_url: '',
      affiliate_url: '',
      demo_video_url: '',
      twitter_url: '',
      facebook_url: '',
      linkedin_url: '',
      pricing: [
        {
          name: '',
          price: '',
          billing_frequency: '',
          trial: '',
          features: [''],
        },
      ],
      frequently_asked_questions: [{ question: '', answer: '' }],
      features: [''],
    },
  })

  const {
    fields: pricingFields,
    append: appendPricing,
    remove: removePricing,
  } = useFieldArray({
    control,
    name: 'pricing',
  })

  const {
    fields: faqFields,
    append: appendFAQ,
    remove: removeFAQ,
  } = useFieldArray({
    control,
    name: 'frequently_asked_questions',
  })

  const {
    fields: featuresFields,
    append: appendFeature,
    remove: removeFeature,
  } = useFieldArray({
    control,
    name: 'features',
  })

  const onSubmit = async (data: any) => {
    try {
      const response = await dispatch(createPlatform(data)).unwrap() // Wait for the response

      if (response.success) {
        // Show success alert only if API response indicates success
        Swal.fire({
          title: 'Platform Created!',
          text: 'The new platform has been successfully added to the system.',
          icon: 'success',
          confirmButtonText: 'OK',
        }).then(() => navigate('/admin/platforms')) // Redirect to platforms list
      } else {
        // Handle case where API response is not successful
        Swal.fire({
          title: 'Error!',
          text: response.error || 'There was an error creating the platform.',
          icon: 'error',
          confirmButtonText: 'OK',
        })
      }
    } catch (error) {
      // Catch any unexpected errors
      Swal.fire({
        title: 'Error!',
        text: 'Something went wrong. Please try again.',
        icon: 'error',
        confirmButtonText: 'OK',
      })
    }
  }

  return (
    <AdminLayout>
      <BreadCrumb title="Create New Platform" url="/admin/platforms" />
      <div className="m-4 bg-white dark:bg-gray-950 rounded-lg shadow">
        <div className="p-6">
          <h1 className="text-xl font-semibold mb-4">Create New Platform</h1>
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* Platform Name */}
            <div className="mb-4">
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700 dark:text-white"
              >
                Platform Name
              </label>
              <input
                type="text"
                id="name"
                {...register('name')}
                className="mt-1 block w-full px-3 py-2 bg-white dark:bg-gray-900 border border-gray-300 dark:border-gray-700 rounded-md shadow-sm"
              />
            </div>

            {/* Rating */}
            <div className="mb-4">
              <label
                htmlFor="rating"
                className="block text-sm font-medium text-gray-700 dark:text-white"
              >
                Rating
              </label>
              <input
                type="number"
                step="0.01"
                id="rating"
                {...register('rating')}
                className="mt-1 block w-full px-3 py-2 bg-white dark:bg-gray-900 border border-gray-300 dark:border-gray-700 rounded-md shadow-sm"
              />
            </div>

            {/* Description */}
            <div className="mb-4">
              <label
                htmlFor="description"
                className="block text-sm font-medium text-gray-700 dark:text-white"
              >
                Description
              </label>
              <textarea
                id="description"
                {...register('description')}
                className="mt-1 block w-full px-3 py-2 bg-white dark:bg-gray-900 border border-gray-300 dark:border-gray-700 rounded-md shadow-sm"
              />
            </div>

            {/* Website */}
            <div className="mb-4">
              <label
                htmlFor="website"
                className="block text-sm font-medium text-gray-700 dark:text-white"
              >
                Website
              </label>
              <input
                type="url"
                id="website"
                {...register('website')}
                className="mt-1 block w-full px-3 py-2 bg-white dark:bg-gray-900 border border-gray-300 dark:border-gray-700 rounded-md shadow-sm"
              />
            </div>

            {/* Image URL */}
            <div className="mb-4">
              <label
                htmlFor="image_url"
                className="block text-sm font-medium text-gray-700 dark:text-white"
              >
                Image URL
              </label>
              <input
                type="text"
                id="image_url"
                {...register('image_url')}
                className="mt-1 block w-full px-3 py-2 bg-white dark:bg-gray-900 border border-gray-300 dark:border-gray-700 rounded-md shadow-sm"
              />
            </div>

            {/* Social Media Links */}
            <div className="mb-4">
              <label
                htmlFor="twitter_url"
                className="block text-sm font-medium text-gray-700 dark:text-white"
              >
                Twitter URL
              </label>
              <input
                type="text"
                id="twitter_url"
                {...register('twitter_url')}
                className="mt-1 block w-full px-3 py-2 bg-white dark:bg-gray-900 border border-gray-300 dark:border-gray-700 rounded-md shadow-sm"
              />
            </div>

            <div className="mb-4">
              <label
                htmlFor="facebook_url"
                className="block text-sm font-medium text-gray-700 dark:text-white"
              >
                Facebook URL
              </label>
              <input
                type="text"
                id="facebook_url"
                {...register('facebook_url')}
                className="mt-1 block w-full px-3 py-2 bg-white dark:bg-gray-900 border border-gray-300 dark:border-gray-700 rounded-md shadow-sm"
              />
            </div>

            <div className="mb-4">
              <label
                htmlFor="linkedin_url"
                className="block text-sm font-medium text-gray-700 dark:text-white"
              >
                LinkedIn URL
              </label>
              <input
                type="text"
                id="linkedin_url"
                {...register('linkedin_url')}
                className="mt-1 block w-full px-3 py-2 bg-white dark:bg-gray-900 border border-gray-300 dark:border-gray-700 rounded-md shadow-sm"
              />
            </div>

            {/* Pricing Plans */}
            <div className="mb-4">
              <h3 className="text-lg font-semibold">Pricing Plans</h3>
              {pricingFields.map((item, index) => (
                <div key={item.id} className="mb-4 border p-4 rounded">
                  <label
                    htmlFor={`pricing.${index}.name`}
                    className="block text-sm font-medium text-gray-700 dark:text-white"
                  >
                    Plan Name
                  </label>
                  <input
                    type="text"
                    id={`pricing.${index}.name`}
                    {...register(`pricing.${index}.name`)}
                    className="mt-1 block w-full px-3 py-2 bg-white dark:bg-gray-900 border border-gray-300 dark:border-gray-700 rounded-md shadow-sm"
                  />

                  <label
                    htmlFor={`pricing.${index}.price`}
                    className="block text-sm font-medium text-gray-700 dark:text-white mt-2"
                  >
                    Price
                  </label>
                  <input
                    type="text"
                    id={`pricing.${index}.price`}
                    {...register(`pricing.${index}.price`)}
                    className="mt-1 block w-full px-3 py-2 bg-white dark:bg-gray-900 border border-gray-300 dark:border-gray-700 rounded-md shadow-sm"
                  />

                  <label
                    htmlFor={`pricing.${index}.billing_frequency`}
                    className="block text-sm font-medium text-gray-700 dark:text-white mt-2"
                  >
                    Billing Frequency
                  </label>
                  <input
                    type="text"
                    id={`pricing.${index}.billing_frequency`}
                    {...register(`pricing.${index}.billing_frequency`)}
                    className="mt-1 block w-full px-3 py-2 bg-white dark:bg-gray-900 border border-gray-300 dark:border-gray-700 rounded-md shadow-sm"
                  />

                  <label
                    htmlFor={`pricing.${index}.trial`}
                    className="block text-sm font-medium text-gray-700 dark:text-white mt-2"
                  >
                    Trial
                  </label>
                  <input
                    type="text"
                    id={`pricing.${index}.trial`}
                    {...register(`pricing.${index}.trial`)}
                    className="mt-1 block w-full px-3 py-2 bg-white dark:bg-gray-900 border border-gray-300 dark:border-gray-700 rounded-md shadow-sm"
                  />

                  {/* Pricing Features */}
                  <label
                    htmlFor={`pricing.${index}.features`}
                    className="block text-sm font-medium text-gray-700 dark:text-white mt-2"
                  >
                    Features
                  </label>
                  <textarea
                    id={`pricing.${index}.features`}
                    {...register(`pricing.${index}.features`)}
                    className="mt-1 block w-full px-3 py-2 bg-white dark:bg-gray-900 border border-gray-300 dark:border-gray-700 rounded-md shadow-sm"
                  />
                  <button
                    type="button"
                    onClick={() => removePricing(index)}
                    className="text-red-600 mt-2"
                  >
                    Remove Plan
                  </button>
                </div>
              ))}
              <button
                type="button"
                onClick={() =>
                  appendPricing({
                    name: '',
                    price: '',
                    billing_frequency: '',
                    trial: '',
                    features: [],
                  })
                }
                className="text-purple-600"
              >
                Add Plan
              </button>
            </div>

            {/* Frequently Asked Questions */}
            <div className="mb-4">
              <h3 className="text-lg font-semibold">FAQs</h3>
              {faqFields.map((item, index) => (
                <div key={item.id} className="mb-4 border p-4 rounded">
                  <label
                    htmlFor={`frequently_asked_questions.${index}.question`}
                    className="block text-sm font-medium text-gray-700 dark:text-white"
                  >
                    Question
                  </label>
                  <input
                    type="text"
                    id={`frequently_asked_questions.${index}.question`}
                    {...register(
                      `frequently_asked_questions.${index}.question`
                    )}
                    className="mt-1 block w-full px-3 py-2 bg-white dark:bg-gray-900 border border-gray-300 dark:border-gray-700 rounded-md shadow-sm"
                  />

                  <label
                    htmlFor={`frequently_asked_questions.${index}.answer`}
                    className="block text-sm font-medium text-gray-700 dark:text-white mt-2"
                  >
                    Answer
                  </label>
                  <input
                    type="text"
                    id={`frequently_asked_questions.${index}.answer`}
                    {...register(`frequently_asked_questions.${index}.answer`)}
                    className="mt-1 block w-full px-3 py-2 bg-white dark:bg-gray-900 border border-gray-300 dark:border-gray-700 rounded-md shadow-sm"
                  />

                  <button
                    type="button"
                    onClick={() => removeFAQ(index)}
                    className="text-red-600 mt-2"
                  >
                    Remove FAQ
                  </button>
                </div>
              ))}
              <button
                type="button"
                onClick={() => appendFAQ({ question: '', answer: '' })}
                className="text-purple-600"
              >
                Add FAQ
              </button>
            </div>

            {/* Features */}
            <div className="mb-4">
              <h3 className="text-lg font-semibold">Features</h3>
              {featuresFields.map((item, index) => (
                <div key={item.id} className="mb-4 border p-4 rounded">
                  <label
                    htmlFor={`features.${index}`}
                    className="block text-sm font-medium text-gray-700 dark:text-white"
                  >
                    Feature
                  </label>
                  <input
                    type="text"
                    id={`features.${index}`}
                    {...register(`features.${index}`)}
                    className="mt-1 block w-full px-3 py-2 bg-white dark:bg-gray-900 border border-gray-300 dark:border-gray-700 rounded-md shadow-sm"
                  />

                  <button
                    type="button"
                    onClick={() => removeFeature(index)}
                    className="text-red-600 mt-2"
                  >
                    Remove Feature
                  </button>
                </div>
              ))}
              <button
                type="button"
                onClick={() => appendFeature('')}
                className="text-purple-600"
              >
                Add Feature
              </button>
            </div>

            <button
              type="submit"
              className="px-4 py-2 bg-purple-600 text-white rounded-md shadow-md hover:bg-purple-700"
            >
              Create Platform
            </button>
          </form>
        </div>
      </div>
    </AdminLayout>
  )
}

export default CreateAdminPlatform

import { Fragment } from 'react'
import { Route, Routes } from 'react-router-dom'
import PrivateRoute from '../../../routing/PrivateRoute'
import BuyerHome from '../Home/Index'
import Settings from '../../Settings/Index'
import Profile from '../../Subscription/SubscriptionDetails/Index'
import Subscription from '../../Subscription/Index'
import SubscriptionSuccess from '../../Subscription/SubscriptionSuccess/Index'
import SubscriptionCancel from '../../Subscription/SubscriptionCancel/Index'
import CreateProject from '../Projects/CreateProject/Index'
import BuyerProjects from '../Projects/Index'
import ShowProject from '../Projects/ShowProject/Index'
import NotFound from '../../../components/Errors/NotFound/Index'
import UserProfile from '../../Profile/Index'
import BuyerMessages from '../Messages/Index'
import BuyerTasks from '../Tasks/Index'

const BuyerRoutes = () => {
  return (
    <Fragment>
      <Routes>
        {/* Buyer Routes */}
        <Route path="/profile" element={<UserProfile />} />

        <Route
          path="/messages"
          element={
            <PrivateRoute>
              <BuyerMessages />
            </PrivateRoute>
          }
        />

        <Route
          path="/settings"
          element={
            <PrivateRoute>
              <Settings />
            </PrivateRoute>
          }
        />

        <Route
          path="/profile"
          element={
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          }
        />

        <Route
          path="/subscription"
          element={
            <PrivateRoute>
              <Subscription />
            </PrivateRoute>
          }
        />

        <Route
          path="/subscription/success"
          element={
            <PrivateRoute>
              <SubscriptionSuccess />
            </PrivateRoute>
          }
        />

        <Route
          path="/subscription/cancel"
          element={
            <PrivateRoute>
              <SubscriptionCancel />
            </PrivateRoute>
          }
        />

        <Route
          path="/projects/new"
          element={
            <PrivateRoute>
              <CreateProject />
            </PrivateRoute>
          }
        />

        <Route
          path="/tasks"
          element={
            <PrivateRoute>
              <BuyerTasks />
            </PrivateRoute>
          }
        />
        <Route
          path="/projects"
          element={
            <PrivateRoute>
              <BuyerProjects />
            </PrivateRoute>
          }
        />

        <Route
          path="/projects/:id/proposals"
          element={
            <PrivateRoute>
              <ShowProject />
            </PrivateRoute>
          }
        />

        <Route
          path="/:username"
          element={
            <PrivateRoute>
              <BuyerHome />
            </PrivateRoute>
          }
        />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </Fragment>
  )
}
export default BuyerRoutes

import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Spinner from '../../../../components/Spinner/Index'
import BreadCrumb from '../../../../components/BreadCrumb/Index'
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline'
import AdminLayout from '../../../../components/Layout/AdminLayout/Index'
import DOMPurify from 'dompurify'
import he from 'he'
import Swal from 'sweetalert2'
import {
  getAdminProject,
  updateAdminProjectStatus,
} from '../../../../store/adminProject/adminProjectReducer'

const ShowAdminProject = () => {
  const dispatch = useDispatch()
  const projectId = useParams().id
  const project = useSelector((state: any) => state.adminProjects.project)

  useEffect(() => {
    dispatch(getAdminProject(projectId))
  }, [dispatch, projectId])

  if (!project) {
    return <Spinner />
  }

  const handleStatusChange = (status: string) => {
    Swal.fire({
      title: `Are you sure you want to ${status.toLowerCase()} this project?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Yes, ${status.toLowerCase()} it!`,
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(updateAdminProjectStatus({ id: projectId, status }))
          .then(() => {
            Swal.fire({
              title: `Project ${status}`,
              text: `The project has been ${status.toLowerCase()}.`,
              icon: 'success',
              confirmButtonText: 'OK',
            })
          })
          .catch((error): void => {
            console.log(error)
            Swal.fire({
              title: 'Error!',
              text: 'There was an error updating the project status.',
              icon: 'error',
              confirmButtonText: 'OK',
            })
          })
      }
    })
  }

  return (
    <AdminLayout>
      <BreadCrumb title="Projects Details" url={`/admin/projects`} />
      <div className="m-4 divide-y divide-gray-200 dark:border dark:border-gray-50 rounded-lg bg-white dark:bg-gray-950 shadow">
        <div className="flex justify-between items-center px-4 py-5 sm:px-6">
          <div>
            <h2 className="text-lg leading-6 font-medium text-gray-900 dark:text-white">
              Project Details
            </h2>
            <p className="mt-1 text-sm text-gray-500 dark:text-white">
              View and manage project details.
            </p>
          </div>
          <div>
            <div className="flex items-center">
              <button onClick={() => handleStatusChange('Approved')}>
                <CheckCircleIcon className="h-10 w-10 text-purple-500" />
              </button>
              <button onClick={() => handleStatusChange('Rejected')}>
                <XCircleIcon className="h-10 w-10 text-red-500" />
              </button>
            </div>
          </div>
        </div>
        <div className="bg-white dark:bg-gray-950 shadow sm:rounded-lg">
          <div className="border-t border-gray-100">
            <dl className="divide-y divide-gray-100">
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-900 dark:text-white">
                  Project Title
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-white">
                  {project.title}
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-900 dark:text-white">
                  Project Budget
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-white">
                  ${project.budget}
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-900 dark:text-white">
                  Description
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-white">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(
                        he.decode(project?.description || 'N/A')
                      ),
                    }}
                  />
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-900 dark:text-white">
                  Client Website
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-white">
                  {project.website}
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-900 dark:text-white">
                  Type of Project
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-white">
                  {project.typeOfProject}
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-900 dark:text-white">
                  Skills Required
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-white">
                  {project.skills?.join(', ')}
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-900 dark:text-white">
                  Primary Technologies
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-white">
                  {project.primaryTech?.join(', ')}
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-900 dark:text-white">
                  Secondary Technologies
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-white">
                  {project.secondaryTech?.join(', ')}
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-900 dark:text-white">
                  Phone
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-white">
                  {project.phone}
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-900 dark:text-white">
                  Milestones
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-white">
                  {Array.isArray(project.milestones) &&
                  project.milestones.length > 0 ? (
                    project.milestones.map((milestone: any) => (
                      <li
                        key={milestone.id}
                        className="border-b pb-2 list-none"
                      >
                        <p className="font-semibold text-gray-900 dark:text-white">
                          {milestone.value}{' '}
                          {/* Accessing 'value' field of milestone */}
                        </p>
                        {/* Render tasks if they exist */}
                        {milestone.tasks && milestone.tasks.length > 0 ? (
                          <ul className="mt-2 ml-4 list-disc">
                            {milestone.tasks.map((task: any) => (
                              <li
                                key={task.id}
                                className="text-gray-700 dark:text-gray-400 list-none"
                              >
                                Task: {task.value}{' '}
                                {/* Accessing 'value' field of each task */}
                              </li>
                            ))}
                          </ul>
                        ) : (
                          <p>No tasks defined for this milestone.</p>
                        )}
                      </li>
                    ))
                  ) : (
                    <p>No milestones defined for this project.</p>
                  )}
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-900 dark:text-white">
                  Status
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-white">
                  {project.status}
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-900 dark:text-white">
                  Likes
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-white">
                  {project.likes}
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </AdminLayout>
  )
}

export default ShowAdminProject

import { useSelector } from 'react-redux'
import BuyerLayout from '../../../components/Layout/BuyerLayout/Index'
import BreadCrumb from '../../../components/BreadCrumb/Index'
import { RootState } from '../../../store'
import { ShoppingCartIcon } from '@heroicons/react/24/outline'
import Panel from '../../../components/Panel/Index'

// Sample CRM tasks for buyers to view
const crmTasksForSale = [
  {
    id: 1,
    taskTitle: 'CRM Setup & Customization',
    href: '#',
    price: '$800.00 USD',
    seller: 'Himanshu B.',
    description:
      'Setup and customize CRM system to align with your business processes.',
    imgUrl: 'https://via.placeholder.com/150',
  },
  {
    id: 2,
    taskTitle: 'CRM Integration with Third-Party Tools',
    href: '#',
    price: '$500.00 USD',
    seller: 'John Doe',
    description:
      'Integrate your CRM with tools like marketing automation, email, and more.',
    imgUrl: 'https://via.placeholder.com/150',
  },
  {
    id: 3,
    taskTitle: 'Sales Pipeline Automation',
    href: '#',
    price: '$650.00 USD',
    seller: 'Jane Smith',
    description:
      'Automate your CRM sales pipeline and streamline the sales process.',
    imgUrl: 'https://via.placeholder.com/150',
  },
  {
    id: 4,
    taskTitle: 'CRM Data Migration',
    href: '#',
    price: '$400.00 USD',
    seller: 'Alice Johnson',
    description:
      'Migrate your existing data into a new CRM system with no data loss.',
    imgUrl: 'https://via.placeholder.com/150',
  },
  {
    id: 5,
    taskTitle: 'Custom CRM Reports & Dashboards',
    href: '#',
    price: '$300.00 USD',
    seller: 'Mark Lee',
    description: 'Create custom reports and dashboards tailored to your KPIs.',
    imgUrl: 'https://via.placeholder.com/150',
  },
  {
    id: 6,
    taskTitle: 'CRM User Training & Documentation',
    href: '#',
    price: '$250.00 USD',
    seller: 'Sarah White',
    description:
      'Provide training sessions and documentation to ensure your team uses CRM effectively.',
    imgUrl: 'https://via.placeholder.com/150',
  },
]

export default function TasksForBuyer() {
  const { user } = useSelector((state: RootState) => state.auth)

  return (
    <BuyerLayout>
      <BreadCrumb title="CRM Tasks" url={`/buyer/${user?.username}`} />
      <Panel
        title="Tasks"
        description="Tasks you have created"
        url={`/seller/tasks/new`}
      >
        {/* CRM Task List in Card View */}
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
            {crmTasksForSale.map((task) => (
              <div
                key={task.id}
                className="bg-white dark:bg-gray-800 shadow-lg rounded-lg overflow-hidden"
              >
                {/* Task Image */}
                <img
                  src={task.imgUrl}
                  alt={task.taskTitle}
                  className="h-40 w-full object-cover"
                />

                {/* Task Content */}
                <div className="p-6">
                  <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                    {task.taskTitle}
                  </h3>
                  <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">
                    {task.description}
                  </p>
                  <p className="mt-4 text-lg font-semibold text-purple-600 dark:text-purple-400">
                    {task.price}
                  </p>
                  <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                    Seller: {task.seller}
                  </p>

                  {/* Buy Now Button */}
                  <div className="mt-6">
                    <a
                      href={task.href}
                      className="inline-flex items-center justify-center w-full px-4 py-2 bg-purple-600 text-white text-sm font-medium rounded-md hover:bg-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                    >
                      <ShoppingCartIcon
                        className="h-5 w-5 mr-2"
                        aria-hidden="true"
                      />
                      Buy Now
                    </a>
                  </div>
                </div>
              </div>
            ))}

            {/* If no tasks are available */}
            {crmTasksForSale.length === 0 && (
              <p className="text-center text-gray-600 dark:text-gray-400 mt-8">
                No CRM tasks available at the moment. Please check back later.
              </p>
            )}
          </div>
        </div>
      </Panel>
    </BuyerLayout>
  )
}

import { useEffect, useState, MouseEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PlusCircleIcon } from '@heroicons/react/24/outline'
import AdminLayout from '../../../components/Layout/AdminLayout/Index'
import BreadCrumb from '../../../components/BreadCrumb/Index'
import Panel from '../../../components/Panel/Index'
import { RootState } from '../../../store'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import { getAllLeads, deleteLead } from '../../../store/leads/leadReducer' // Import actions from leadReducer

const LeadsAdminPage = () => {
  const dispatch = useDispatch()
  const [shouldRefetch, setShouldRefetch] = useState(false)
  const { leads } = useSelector((state: RootState) => state.leads) // Access leads from Redux state

  console.log(leads)

  useEffect(() => {
    dispatch(getAllLeads()) // Fetch leads on component mount or refetch trigger
  }, [dispatch, shouldRefetch])

  const handleDelete = async (
    e: MouseEvent<HTMLAnchorElement, MouseEvent>,
    leadId: string
  ) => {
    e.preventDefault()

    // Display a SweetAlert2 confirmation dialog
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    })

    if (result.isConfirmed) {
      await dispatch(deleteLead(leadId) as any) // Delete the lead
      setShouldRefetch(!shouldRefetch) // Trigger a re-fetch after deletion
    }
  }

  return (
    <AdminLayout>
      <BreadCrumb title="Leads Management" url="/admin/" />
      <Panel
        title="Leads"
        description="View and manage leads."
        url="/admin/leads/new"
        icon={PlusCircleIcon}
      >
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="-mx-4 sm:-mx-0">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-white sm:pl-0"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white sm:table-cell"
                  >
                    Email
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white"
                  >
                    Company
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white sm:table-cell"
                  >
                    Location
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white sm:table-cell"
                  >
                    CRM
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white"
                  >
                    Status
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                    <span className="sr-only">Actions</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white dark:bg-gray-950">
                {leads.length > 0 ? (
                  leads.map((lead) => (
                    <tr key={lead._id}>
                      <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 dark:text-white sm:w-auto sm:max-w-none sm:pl-0">
                        {lead.name}
                        <dl className="font-normal lg:hidden">
                          <dt className="sr-only sm:hidden">Email</dt>
                          <dd className="mt-1 truncate text-gray-500 dark:text-white sm:hidden">
                            {lead.email}
                          </dd>
                          <dt className="sr-only">Company</dt>
                          <dd className="mt-1 truncate dark:text-white text-gray-700">
                            {lead.company}
                          </dd>
                          <dt className="sr-only">Location</dt>
                          <dd className="mt-1 truncate dark:text-white text-gray-700">
                            {lead.location}
                          </dd>
                          <dt className="sr-only">CRM</dt>
                          <dd className="mt-1 truncate dark:text-white text-gray-700">
                            {lead.crm} (ID: {lead.crm_id})
                          </dd>
                        </dl>
                      </td>
                      <td className="hidden px-3 py-4 text-sm text-gray-500 dark:text-white sm:table-cell">
                        {lead.email}
                      </td>
                      <td className="px-3 py-4 text-sm text-gray-500 dark:text-white">
                        {lead.company}
                      </td>
                      <td className="hidden px-3 py-4 text-sm text-gray-500 dark:text-white sm:table-cell">
                        {lead.location}
                      </td>
                      <td className="hidden px-3 py-4 text-sm text-gray-500 dark:text-white sm:table-cell">
                        {lead.crm} (ID: {lead.crm_id})
                      </td>
                      <td className="px-3 py-4 text-sm text-gray-500 dark:text-white">
                        <span
                          className={`inline-flex items-center px-2 py-0.5 rounded text-xs font-medium ${
                            lead.status === 'new'
                              ? 'bg-yellow-100 text-yellow-800'
                              : lead.status === 'in-progress'
                              ? 'bg-blue-100 text-blue-800'
                              : 'bg-green-100 text-green-800'
                          }`}
                        >
                          {lead.status}
                        </span>
                      </td>
                      <td className="flex py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                        <Link
                          to={`/admin/leads/${lead._id}`}
                          className="text-purple-600 hover:text-purple-900 mr-4"
                        >
                          View<span className="sr-only">, {lead.name}</span>
                        </Link>
                        <Link
                          to={`/admin/leads/${lead._id}/edit`}
                          className="text-purple-600 hover:text-purple-900 mr-4"
                        >
                          Edit<span className="sr-only">, {lead.name}</span>
                        </Link>
                        <Link
                          onClick={(
                            e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
                          ) => handleDelete(e, lead._id)}
                          to="#"
                          className="text-red-600 hover:text-red-900"
                        >
                          Delete<span className="sr-only">, {lead.name}</span>
                        </Link>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={7}
                      className="py-4 text-center text-sm text-gray-500 dark:text-white"
                    >
                      No leads found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </Panel>
    </AdminLayout>
  )
}

export default LeadsAdminPage

import BreadCrumb from '../../../components/BreadCrumb/Index'
import BuyerLayout from '../../../components/Layout/BuyerLayout/Index'

const BuyerHome = () => {
  return (
    <BuyerLayout>
      <BreadCrumb title="Buyer Home" />
    </BuyerLayout>
  )
}
export default BuyerHome

import { useEffect } from 'react'
import {
  getAllBuyerProjects,
  getBuyerProject,
} from '../../../../store/buyerProject/buyerProjectReducer'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Spinner from '../../../../components/Spinner/Index'
import BuyerLayout from '../../../../components/Layout/BuyerLayout/Index'
import BreadCrumb from '../../../../components/BreadCrumb/Index'
import Panel from '../../../../components/Panel/Index'
import DOMPurify from 'dompurify'
import he from 'he'

const ShowProject = () => {
  const dispatch = useDispatch()
  const projectId = useParams()
  const project = useSelector((state: any) => state.buyerProjects.project)
  const proposals = useSelector((state: any) => state.proposals.data)

  useEffect(() => {
    dispatch(getBuyerProject(projectId.id) as any)
    dispatch(getAllBuyerProjects(projectId.id) as any)
  }, [])

  if (!project) <Spinner />

  return (
    <BuyerLayout>
      <BreadCrumb title="Projects Details" url={`/buyer/projects`} />
      <Panel
        title="Project Details"
        description="Your Project details and proposals."
      >
        <div className="bg-white dark:bg-gray-950 shadow sm:rounded-lg">
          <div className="border-t border-gray-100">
            <dl className="divide-y divide-gray-100">
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-900 dark:text-white">
                  Project Title
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-white">
                  {project.title}
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-900 dark:text-white">
                  Project Budget
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-white">
                  ${project.budget}
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-900 dark:text-white">
                  Description
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-white">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(
                        he.decode(project?.description || 'N/A')
                      ),
                    }}
                  />
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-900 dark:text-white">
                  Client Website
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-white">
                  {project.website}
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-900 dark:text-white">
                  Type of Project
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-white">
                  {project.typeOfProject}
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-900 dark:text-white">
                  Skills Required
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-white">
                  {project.skills?.join(', ')}
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-900 dark:text-white">
                  Primary Technologies
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-white">
                  {project.primaryTech?.join(', ')}
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-900 dark:text-white">
                  Secondary Technologies
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-white">
                  {project.secondaryTech?.join(', ')}
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-900 dark:text-white">
                  Phone
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-white">
                  {project.phone}
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-900 dark:text-white">
                  Milestones
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-white">
                  {Array.isArray(project.milestones) &&
                  project.milestones.length > 0 ? (
                    project.milestones.map((milestone: any) => (
                      <li
                        key={milestone.id}
                        className="border-b pb-2 list-none"
                      >
                        <p className="font-semibold text-gray-900 dark:text-white">
                          {milestone.value}{' '}
                          {/* Accessing 'value' field of milestone */}
                        </p>
                        {/* Render tasks if they exist */}
                        {milestone.tasks && milestone.tasks.length > 0 ? (
                          <ul className="mt-2 ml-4 list-disc">
                            {milestone.tasks.map((task: any) => (
                              <li
                                key={task.id}
                                className="text-gray-700 dark:text-gray-400 list-none"
                              >
                                Task: {task.value}{' '}
                                {/* Accessing 'value' field of each task */}
                              </li>
                            ))}
                          </ul>
                        ) : (
                          <p>No tasks defined for this milestone.</p>
                        )}
                      </li>
                    ))
                  ) : (
                    <p>No milestones defined for this project.</p>
                  )}
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-900 dark:text-white">
                  Status
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-white">
                  {project.status}
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-900 dark:text-white">
                  Likes
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-white">
                  {project.likes}
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </Panel>
      <div>
        {proposals?.map((proposal: any) => (
          <div key={proposal._id}>
            {proposal.description}
            {proposal.budget}
            {proposal.duration}
            {proposal.coverLetter}
            {proposal.status}
          </div>
        ))}
      </div>
    </BuyerLayout>
  )
}
export default ShowProject

import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Spinner from '../../../../components/Spinner/Index'
import BreadCrumb from '../../../../components/BreadCrumb/Index'
import AdminLayout from '../../../../components/Layout/AdminLayout/Index'
import { fetchPlatformById } from '../../../../store/platforms/platformReducer' // Update the import path as necessary

const ShowAdminPlatform = () => {
  const dispatch = useDispatch()
  const platformId = useParams().id
  const platform = useSelector((state: any) => state.platforms.platform)

  useEffect(() => {
    dispatch(fetchPlatformById(platformId))
  }, [dispatch, platformId])

  if (!platform) {
    return <Spinner />
  }

  return (
    <AdminLayout>
      <BreadCrumb title="Platform Details" url={`/admin/platforms`} />
      <div className="m-4 divide-y divide-gray-200 dark:border dark:border-gray-50 rounded-lg bg-white dark:bg-gray-950 shadow">
        <div className="flex justify-between items-center px-4 py-5 sm:px-6">
          <div>
            <h2 className="text-lg leading-6 font-medium text-gray-900 dark:text-white">
              Platform Details
            </h2>
            <p className="mt-1 text-sm text-gray-500 dark:text-white">
              View platform details.
            </p>
          </div>
        </div>
        <div className="bg-white dark:bg-gray-950 shadow sm:rounded-lg">
          <div className="border-t border-gray-100">
            <dl className="divide-y divide-gray-100">
              {/* Basic Platform Information */}
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-900 dark:text-white">
                  Platform Name
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-white">
                  {platform.name}
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-900 dark:text-white">
                  Rating
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-white">
                  {platform.rating}
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-900 dark:text-white">
                  Description
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-white">
                  {platform.description || 'No description available'}
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-900 dark:text-white">
                  Website
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-white">
                  <a
                    href={platform.website}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {platform.website}
                  </a>
                </dd>
              </div>

              {/* Pricing Section */}
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-900 dark:text-white">
                  Pricing Plans
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-white">
                  {platform.pricing?.plans &&
                  platform.pricing.plans.length > 0 ? (
                    <ul>
                      {platform.pricing.plans.map(
                        (plan: any, index: number) => (
                          <li key={index} className="mb-4">
                            <h3 className="font-semibold text-lg">
                              {plan.name}
                            </h3>
                            <p>
                              Price: ${plan.price} {plan.billing_frequency}
                            </p>
                            <p>Description: {plan.description}</p>
                            {plan.includes && <p>Includes: {plan.includes}</p>}
                            <h4 className="font-semibold mt-2">Features:</h4>
                            <ul className="list-disc ml-5">
                              {plan.features.map(
                                (feature: string, featureIndex: number) => (
                                  <li key={featureIndex}>{feature}</li>
                                )
                              )}
                            </ul>
                          </li>
                        )
                      )}
                    </ul>
                  ) : (
                    'No pricing information available'
                  )}
                </dd>
              </div>

              {/* Frequently Asked Questions Section */}
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-900 dark:text-white">
                  Frequently Asked Questions (FAQs)
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-white">
                  {platform.frequently_asked_questions?.questions?.length >
                  0 ? (
                    <ul>
                      {platform.frequently_asked_questions.questions.map(
                        (faq: any, index: number) => (
                          <li key={index} className="mb-2">
                            <strong>Q:</strong> {faq.question} <br />
                            <strong>A:</strong> {faq.answer}
                          </li>
                        )
                      )}
                    </ul>
                  ) : (
                    'No FAQs available'
                  )}
                </dd>
              </div>

              {/* Features Section */}
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-900 dark:text-white">
                  Features
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-white">
                  {platform.features && platform.features.length > 0 ? (
                    <ul>
                      {platform.features.map(
                        (feature: string, index: number) => (
                          <li key={index}>{feature}</li>
                        )
                      )}
                    </ul>
                  ) : (
                    'No features listed'
                  )}
                </dd>
              </div>

              {/* Additional Information like affiliate_url, demo_video_url, and social media */}
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-900 dark:text-white">
                  Affiliate URL
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-white">
                  <a
                    href={platform.affiliate_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {platform.affiliate_url || 'N/A'}
                  </a>
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-900 dark:text-white">
                  Demo Video URL
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-white">
                  {platform.demo_video_url || 'No video available'}
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-900 dark:text-white">
                  Social Media
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-white">
                  Twitter: {platform.twitter_url || 'N/A'}
                  <br />
                  Facebook: {platform.facebook_url || 'N/A'}
                  <br />
                  LinkedIn: {platform.linkedin_url || 'N/A'}
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </AdminLayout>
  )
}

export default ShowAdminPlatform

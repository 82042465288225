import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import BreadCrumb from '../../../components/BreadCrumb/Index'
import { RootState } from '../../../store'
import { PaperAirplaneIcon } from '@heroicons/react/24/solid'
import { ArrowUpTrayIcon, FaceSmileIcon } from '@heroicons/react/24/outline'
import EmojiPicker from 'emoji-picker-react'
import BuyerLayout from '../../../components/Layout/BuyerLayout/Index'

// Simulated presence tracking (normally this comes from a WebSocket or API)
const simulatePresence = (users) => {
  return users.map((user) => ({
    ...user,
    online: Math.random() > 0.5, // Simulate 50% chance of being online
  }))
}

const BuyerMessages = () => {
  const { user } = useSelector((state: RootState) => state.auth)
  const [messages, setMessages] = useState([])
  const [input, setInput] = useState('')
  const [activeChat, setActiveChat] = useState(null)
  const [searchQuery, setSearchQuery] = useState('')
  const [showEmojiPicker, setShowEmojiPicker] = useState(false)
  const [users, setUsers] = useState([
    { id: 1, name: 'John Doe', online: false },
    { id: 2, name: 'Jane Smith', online: false },
    { id: 3, name: 'Alice Johnson', online: false },
    // Add more users here
  ])

  // Correct the handleEmojiClick function
  const handleEmojiClick = (emojiObject) => {
    setInput((prevInput) => prevInput + emojiObject.emoji) // Correctly append the emoji
    setShowEmojiPicker(false) // Close emoji picker after selecting an emoji
  }

  // Simulate fetching users' online status
  useEffect(() => {
    const interval = setInterval(() => {
      const updatedUsers = simulatePresence(users)
      setUsers(updatedUsers)
    }, 5000)

    return () => clearInterval(interval)
  }, [users])

  // Filtered users based on the search query
  const filteredUsers = users.filter((userItem) =>
    userItem.name.toLowerCase().includes(searchQuery.toLowerCase())
  )

  // Simulate sending a message
  const sendMessage = (e) => {
    e.preventDefault()
    if (input.trim() !== '' && activeChat) {
      setMessages([
        ...messages,
        { id: messages.length, text: input, sender: user?.username },
      ])
      setInput('')
    }
  }

  return (
    <BuyerLayout>
      <BreadCrumb title="Messages" url={`/seller/${user?.username}`} />

      <div className="p-4 flex flex-col md:flex-row h-[93vh] overflow-hidden dark:bg-gray-950">
        <div className="w-full md:w-64 rounded-lg shadow-lg bg-white dark:bg-gray-950 text-gray-950 dark:text-white p-4 border-b md:border-b-0 md:border-r border-gray-300 dark:border-gray-700">
          <h3 className="text-lg font-semibold mb-4">Users</h3>

          {/* Search Bar */}
          <div className="mb-4">
            <input
              type="text"
              placeholder="Search users..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 dark:border-gray-700 rounded-lg text-gray-950 dark:text-white bg-white dark:bg-gray-950 focus:ring-purple-500 focus:border-purple-500"
            />
          </div>

          {/* User List */}
          <ul className="space-y-2">
            {filteredUsers.length > 0 ? (
              filteredUsers.map((userItem) => (
                <li key={userItem.id} className="cursor-pointer rounded-lg">
                  {/* Use button inside li for interaction */}
                  <button
                    onClick={() => setActiveChat(userItem)}
                    className={`w-full text-left p-2 rounded-lg ${
                      activeChat?.id === userItem.id
                        ? 'bg-purple-600 text-white'
                        : 'hover:bg-gray-100 dark:hover:bg-gray-700 dark:text-white'
                    }`}
                  >
                    <div className="flex items-center space-x-2">
                      <span
                        className={`inline-block h-2 w-2 rounded-full ${
                          userItem.online ? 'bg-green-500' : 'bg-gray-400'
                        }`}
                      ></span>
                      <span>{userItem.name}</span>
                    </div>
                  </button>
                </li>
              ))
            ) : (
              <li className="text-gray-500 dark:text-gray-400">
                No users found
              </li>
            )}
          </ul>
        </div>

        <div className="flex flex-col flex-grow rounded-md shadow-lg bg-white dark:bg-gray-950 text-gray-950 dark:text-white">
          <div className="flex items-center justify-between px-6 py-4 bg-white dark:bg-gray-950 border-b border-gray-300 dark:border-gray-700">
            <h2 className="text-xl font-semibold">
              {activeChat ? activeChat.name : 'Select a user to chat'}
            </h2>
          </div>

          {/* Messages Section */}
          <div className="flex-grow overflow-y-auto p-4 space-y-4 bg-white dark:bg-gray-950">
            {activeChat ? (
              messages.map((msg) => (
                <div key={msg.id} className="flex items-start space-x-3">
                  <div className="flex-shrink-0">
                    <img
                      className="h-8 w-8 rounded-full"
                      src="https://via.placeholder.com/40"
                      alt="User"
                    />
                  </div>
                  <div className="bg-gray-100 dark:bg-gray-950 px-4 py-2 rounded-lg shadow-sm ring-1 ring-gray-200 dark:ring-gray-700">
                    <p className="text-sm leading-5 text-gray-950 dark:text-white">
                      {msg.text}
                    </p>
                  </div>
                </div>
              ))
            ) : (
              <p className="text-gray-500 dark:text-gray-400">
                Please select a user to start chatting.
              </p>
            )}
          </div>

          {/* Chat Input Section */}
          <form
            onSubmit={sendMessage}
            className="border-t border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-950 p-4"
          >
            <div className="flex items-center space-x-3 relative">
              <textarea
                value={input}
                onChange={(e) => setInput(e.target.value)}
                placeholder={
                  activeChat
                    ? 'Type a message'
                    : 'Select a user to start chatting'
                }
                className="flex-grow border border-gray-300 dark:bg-gray-950 dark:border-gray-700 rounded-lg py-2 px-4 text-gray-950 dark:text-white placeholder-gray-500 dark:placeholder-gray-400 focus:ring-purple-500 focus:border-purple-500"
                disabled={!activeChat} // Disable if no chat is active
              />
              <div className="flex items-center space-x-3">
                <div className="relative">
                  <button
                    type="button"
                    className="inline-flex items-center justify-center rounded-full bg-purple-600 p-2 text-white shadow-sm hover:bg-purple-500 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                    onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                  >
                    <FaceSmileIcon className="h-5 w-5" aria-hidden="true" />
                  </button>

                  {showEmojiPicker && (
                    <div className="absolute bottom-12 right-0 z-50">
                      <EmojiPicker onEmojiClick={handleEmojiClick} />
                    </div>
                  )}
                </div>

                <label
                  htmlFor="file-upload"
                  className="inline-flex items-center justify-center rounded-full bg-purple-600 p-2 text-white shadow-sm hover:bg-purple-500 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 cursor-pointer"
                >
                  <ArrowUpTrayIcon className="h-5 w-5" aria-hidden="true" />
                  <input
                    id="file-upload"
                    type="file"
                    className="sr-only"
                    onChange={(e) => {
                      const file = e.target.files[0]
                      if (file) {
                        console.log('File selected:', file.name)
                      }
                    }}
                  />
                </label>
              </div>
              <button
                type="submit"
                className={`inline-flex items-center justify-center rounded-full bg-purple-600 p-2 text-white shadow-sm hover:bg-purple-500 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 ${
                  !activeChat ? 'opacity-50 cursor-not-allowed' : ''
                }`}
                disabled={!activeChat} // Disable if no chat is active
              >
                <PaperAirplaneIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </BuyerLayout>
  )
}

export default BuyerMessages

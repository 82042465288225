import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import BuyerLayout from '../../../components/Layout/BuyerLayout/Index'
import { RootState } from '../../../store'
import BreadCrumb from '../../../components/BreadCrumb/Index'
import Panel from '../../../components/Panel/Index'
import ProjectCard from '../../../components/Project/ProjectCard/Index'
import { getAllBuyerProjects } from '../../../store/buyerProject/buyerProjectReducer'
import { PlusCircleIcon } from '@heroicons/react/24/outline'
import NoProjectsSVG from '../../../assets/images/svg/icon-01.svg'
import { Link } from 'react-router-dom'

const BuyerProjects = () => {
  const [selectedTab, setSelectedTab] = useState('Approved')
  const { userProjects } = useSelector(
    (state: RootState) => state.buyerProjects
  )
  const { user } = useSelector((state: RootState) => state.auth)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAllBuyerProjects())
  }, [dispatch])

  const tabs = [
    { name: 'Approved', key: 'approved' },
    { name: 'Pending', key: 'pending' },
    { name: 'Rejected', key: 'rejected' },
  ]

  const filterProjectsByStatus = (projects: any[], status: string) => {
    return projects.filter((project) => project.status.toLowerCase() === status)
  }

  const filteredProjects = filterProjectsByStatus(
    userProjects,
    selectedTab.toLowerCase()
  )

  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
  }

  return (
    <BuyerLayout>
      <BreadCrumb title="Projects" url={`/buyer/${user?.username}`} />

      <Panel
        title="Projects"
        description="View and manage your projects."
        url="/buyer/projects/new"
        icon={PlusCircleIcon}
      >
        <main className="lg:col-span-9 xl:col-span-6 w-full">
          {/* Check if there are any projects before rendering tabs */}
          {userProjects.length > 0 && (
            <div className="px-4 py-4 sm:px-0">
              {/* Mobile tabs */}
              <div className="sm:hidden">
                <label htmlFor="project-tabs" className="sr-only">
                  Select a tab
                </label>
                <select
                  id="project-tabs"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white dark:bg-gray-950 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-purple-500"
                  value={selectedTab}
                  onChange={(e) => setSelectedTab(e.target.value)}
                >
                  {tabs.map((tab) => (
                    <option key={tab.key} value={tab.name}>
                      {tab.name}
                    </option>
                  ))}
                </select>
              </div>
              {/* Desktop tabs */}
              <div className="hidden sm:block">
                <nav
                  className="isolate flex divide-x divide-gray-200 rounded-lg shadow w-full"
                  aria-label="Tabs"
                >
                  {tabs.map((tab, tabIdx) => (
                    <button
                      key={tab.name}
                      onClick={() => setSelectedTab(tab.name)}
                      className={classNames(
                        tab.name === selectedTab
                          ? 'text-gray-900 dark:text-white'
                          : 'text-gray-500 hover:text-gray-700',
                        tabIdx === 0 ? 'rounded-l-lg' : '',
                        tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '',
                        'group relative min-w-0 flex-1 overflow-hidden bg-white dark:bg-gray-950 py-4 px-6 text-center text-sm font-medium hover:bg-gray-50 focus:z-10'
                      )}
                    >
                      <span>{tab.name}</span>
                      <span
                        aria-hidden="true"
                        className={classNames(
                          tab.name === selectedTab
                            ? 'bg-purple-500'
                            : 'bg-transparent',
                          'absolute inset-x-0 bottom-0 h-0.5'
                        )}
                      />
                    </button>
                  ))}
                </nav>
              </div>
            </div>
          )}

          <div className="mt-4 px-4 w-full">
            <h1 className="sr-only">{selectedTab} Projects</h1>

            {/* Display project cards */}
            <div className="space-y-2 w-full">
              {filteredProjects.length > 0 ? (
                filteredProjects.map((project) => (
                  <div
                    key={project._id}
                    className="bg-white dark:bg-gray-950 border-2 border-b-2 shadow rounded-lg flex justify-between items-center w-full"
                  >
                    <div className="w-full">
                      <ProjectCard userProjects={[project]} />
                    </div>
                  </div>
                ))
              ) : (
                <div className="flex flex-col items-center">
                  <img
                    src={NoProjectsSVG}
                    alt="No Projects"
                    className="h-72 w-auto"
                  />
                  <Link
                    to={`/buyer/projects/new`}
                    className="rounded-md bg-purple-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-purple-500 focus:outline-none focus:ring-2 focus:ring-purple-600"
                  >
                    Start a new project
                  </Link>
                </div>
              )}
            </div>
          </div>
        </main>
      </Panel>
    </BuyerLayout>
  )
}

export default BuyerProjects

import { PlusCircleIcon } from '@heroicons/react/24/outline'
import BreadCrumb from '../../../../components/BreadCrumb/Index'
import AdminLayout from '../../../../components/Layout/AdminLayout/Index'
import Panel from '../../../../components/Panel/Index'

const ShowUser = () => {
  return (
    <AdminLayout>
      <BreadCrumb title="View User" url="/admin/users" />
      <Panel
        title="User Details"
        description="View a single user details"
        url="/admin/users"
        icon={PlusCircleIcon}
      ></Panel>
    </AdminLayout>
  )
}
export default ShowUser

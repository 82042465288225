import AdminLayout from '../../../components/Layout/AdminLayout/Index'

const AdminHome = () => {
  return (
    <AdminLayout>
      <div className="sticky top-0 flex h-20 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white dark:bg-gray-950 px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
        <div className="flex items-center">
          <h1 className="text-2xl dark:text-white font-bold text-gray-700 ml-4">
            Admin Home
          </h1>
        </div>
      </div>
      <div className="m-4 divide-y divide-gray-200 dark:border dark:border-white overflow-hidden rounded-lg bg-white dark:bg-gray-950 shadow">
        <div className="px-4 py-5 sm:px-6">
          <h2 className="text-lg font-medium leading-6 dark:text-white text-gray-900">
            Admin Home
          </h2>
          <p className="mt-1 text-sm dark:text-white text-gray-500">
            Welcome to the Admin Home page.
          </p>
        </div>
        <div className="px-4 py-5 sm:p-6"></div>
      </div>
    </AdminLayout>
  )
}
export default AdminHome

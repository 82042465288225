import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useRef, useState } from 'react'
import AdminLayout from '../../../components/Layout/AdminLayout/Index'
import { RootState } from '../../../store'
import BreadCrumb from '../../../components/BreadCrumb/Index'
import Panel from '../../../components/Panel/Index'
import ProjectCard from '../../Admin/Projects/ProjectCard/Index'
import { getAllAdminProjects } from '../../../store/adminProject/adminProjectReducer'
import NoProjectsSVG from '../../../assets/images/svg/icon-01.svg'

const AdminProjects = () => {
  const [selectedTab, setSelectedTab] = useState('Pending')
  const { userProjects } = useSelector(
    (state: RootState) => state.adminProjects
  )
  const containerRef = useRef(null)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAllAdminProjects())
  }, [dispatch])

  const tabs = [
    { name: 'Approved', key: 'approved' },
    { name: 'Pending', key: 'pending' },
    { name: 'Rejected', key: 'rejected' },
  ]

  const filterProjectsByStatus = (projects: any[], status: string) => {
    return projects.filter((project) => project.status.toLowerCase() === status)
  }

  const filteredProjects = filterProjectsByStatus(
    userProjects,
    selectedTab.toLowerCase()
  )

  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
  }

  return (
    <AdminLayout>
      <BreadCrumb title="Projects" />

      <Panel title="Projects" description="Here you can view all projects.">
        <main className="lg:col-span-9 xl:col-span-6 w-full">
          <div className="px-4 py-4 sm:px-0">
            <div className="sm:hidden">
              <label htmlFor="project-tabs" className="sr-only">
                Select a tab
              </label>
              <select
                id="project-tabs"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white dark:bg-gray-950 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-purple-500"
                value={selectedTab}
                onChange={(e) => setSelectedTab(e.target.value)}
              >
                {tabs.map((tab) => (
                  <option key={tab.key} value={tab.name}>
                    {tab.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="hidden sm:block">
              <nav
                className="isolate flex divide-x divide-gray-200 rounded-lg shadow w-full"
                aria-label="Tabs"
              >
                {tabs.map((tab, tabIdx) => (
                  <button
                    key={tab.name}
                    onClick={() => setSelectedTab(tab.name)}
                    className={classNames(
                      tab.name === selectedTab
                        ? 'text-gray-900 dark:text-white'
                        : 'text-gray-500 hover:text-gray-700',
                      tabIdx === 0 ? 'rounded-l-lg' : '',
                      tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '',
                      'group relative min-w-0 flex-1 overflow-hidden bg-white dark:bg-gray-950 py-4 px-6 text-center text-sm font-medium hover:bg-gray-50 focus:z-10'
                    )}
                  >
                    <span>{tab.name}</span>
                    <span
                      aria-hidden="true"
                      className={classNames(
                        tab.name === selectedTab
                          ? 'bg-purple-500'
                          : 'bg-transparent',
                        'absolute inset-x-0 bottom-0 h-0.5'
                      )}
                    />
                  </button>
                ))}
              </nav>
            </div>
          </div>

          <div ref={containerRef} className="mt-4 px-4 w-full space-y-2">
            <h1 className="sr-only">{selectedTab} Projects</h1>

            {/* Render list of filtered projects */}
            {filteredProjects.length > 0 ? (
              filteredProjects.map((project) => (
                <div
                  key={project._id}
                  className="bg-white dark:bg-gray-950 border-2 border-b-2 shadow rounded-lg flex justify-between items-center w-full"
                >
                  <div className="w-full">
                    <ProjectCard userProjects={[project]} />
                  </div>
                </div>
              ))
            ) : (
              <div className="flex flex-col items-center">
                <img
                  src={NoProjectsSVG}
                  alt="No Projects"
                  className="h-64 w-auto"
                />
                <p className="py-4">No {selectedTab} projects found.</p>
              </div>
            )}
          </div>
        </main>
      </Panel>
    </AdminLayout>
  )
}

export default AdminProjects

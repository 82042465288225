import * as ReactDOM from 'react-dom/client'
import './assets/css/tailwind.css'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './App'
import { Provider } from 'react-redux'
import { store, persistor } from './store/index'
import { Toaster } from 'react-hot-toast'
import { PersistGate } from 'redux-persist/integration/react'

ReactDOM.createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router>
        <App />
        <Toaster position="top-center" reverseOrder={false} />
      </Router>
    </PersistGate>
  </Provider>
)

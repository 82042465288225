import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { createUser } from '../../../../store/user/userReducer'
import AdminLayout from '../../../../components/Layout/AdminLayout/Index'
import BreadCrumb from '../../../../components/BreadCrumb/Index'
import Panel from '../../../../components/Panel/Index'

const CreateUser = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const dispatch = useDispatch()
  const navigate = useNavigate() // Use useNavigate for React Router v6

  // Function to handle form submission
  const onSubmit = async (data) => {
    await dispatch(createUser(data))
    navigate('/admin/users') // Redirect to users list after creation
  }

  return (
    <AdminLayout>
      <BreadCrumb parentLink="/" parentTitle="Dashboard" leaf="Create User" />
      <Panel title="Create User" description="Create a new user in the system">
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
          <div>
            <label
              htmlFor="first_name"
              className="block text-sm font-medium text-gray-700"
            >
              First Name
            </label>
            <input
              type="text"
              {...register('first_name', {
                required: 'First name is required',
              })}
              id="first_name"
              className={`mt-1 block w-full px-3 py-2 border ${
                errors.first_name ? 'border-red-500' : 'border-gray-300'
              } rounded-md shadow-sm`}
            />
            {errors.first_name && (
              <p className="text-red-500 text-sm mt-1">
                {errors.first_name.message}
              </p>
            )}
          </div>

          <div>
            <label
              htmlFor="last_name"
              className="block text-sm font-medium text-gray-700"
            >
              Last Name
            </label>
            <input
              type="text"
              {...register('last_name', { required: 'Last name is required' })}
              id="last_name"
              className={`mt-1 block w-full px-3 py-2 border ${
                errors.last_name ? 'border-red-500' : 'border-gray-300'
              } rounded-md shadow-sm`}
            />
            {errors.last_name && (
              <p className="text-red-500 text-sm mt-1">
                {errors.last_name.message}
              </p>
            )}
          </div>

          <div>
            <label
              htmlFor="username"
              className="block text-sm font-medium text-gray-700"
            >
              Username
            </label>
            <input
              type="text"
              {...register('username', { required: 'Username is required' })}
              id="username"
              className={`mt-1 block w-full px-3 py-2 border ${
                errors.username ? 'border-red-500' : 'border-gray-300'
              } rounded-md shadow-sm`}
            />
            {errors.username && (
              <p className="text-red-500 text-sm mt-1">
                {errors.username.message}
              </p>
            )}
          </div>

          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Email
            </label>
            <input
              type="email"
              {...register('email', {
                required: 'Email is required',
                pattern: {
                  value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                  message: 'Please enter a valid email address',
                },
              })}
              id="email"
              className={`mt-1 block w-full px-3 py-2 border ${
                errors.email ? 'border-red-500' : 'border-gray-300'
              } rounded-md shadow-sm`}
            />
            {errors.email && (
              <p className="text-red-500 text-sm mt-1">
                {errors.email.message}
              </p>
            )}
          </div>

          <div>
            <label
              htmlFor="role"
              className="block text-sm font-medium text-gray-700"
            >
              Role
            </label>
            <select
              {...register('role', { required: 'Role is required' })}
              id="role"
              className={`mt-1 block w-full px-3 py-2 border ${
                errors.role ? 'border-red-500' : 'border-gray-300'
              } rounded-md shadow-sm`}
            >
              <option value="ROLE_USER">User</option>
            </select>
            {errors.role && (
              <p className="text-red-500 text-sm mt-1">{errors.role.message}</p>
            )}
          </div>

          <button
            type="submit"
            className="rounded-md bg-purple-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-purple-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600"
          >
            Create User
          </button>
        </form>
      </Panel>
    </AdminLayout>
  )
}

export default CreateUser

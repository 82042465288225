import { PlusCircleIcon } from '@heroicons/react/24/outline'
import Form from '../../../../components/Form/Index'
import BuyerLayout from '../../../../components/Layout/BuyerLayout/Index'
import BreadCrumb from '../../../../components/BreadCrumb/Index'
import Panel from '../../../../components/Panel/Index'

const CreateProject = () => {
  return (
    <BuyerLayout>
      <BreadCrumb title="Create Project" url="/buyer/projects" />
      <Panel
        title="Create Project"
        description="Create a new project."
        url="/buyer/projects/new"
        icon={PlusCircleIcon}
      >
        <Form />
      </Panel>
    </BuyerLayout>
  )
}
export default CreateProject
